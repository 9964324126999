.section-custom-hero-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
    background-color: white;
}

.section-custom-hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    position: relative;
}

.section-custom-hero-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.overlay-content {
    max-width: 375px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 60px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.overlay-title {
    font-size: 40px;
    color: black;
    margin-bottom: -5px;
    font-family: 'Sabon';
    font-weight: 400;
}

.overlay-text {
    font-size: 16px;
    color: black;
    margin-top: 15px;
    margin-bottom: 30px;
}

.buttons-row {
    display: flex;
    gap: 20px;
}

.overlay-button {
    padding: 12px 20px;
    cursor: pointer;
    border: none;
    background-color: var(--marketplaceColor);
    width: 140px;
    color: white;
    font-weight: 500;
    font-size: 16px;
}

@media (max-width: 1200px) {
    .section-custom-hero {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .section-custom-hero-image {
        height: 100%;
        object-fit: cover;
    }

    .overlay-content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        margin-left: 0px;
        margin-top: 0px;
    }

    .overlay-title {
        font-size: 30px;
        color: var(--marketplaceColor);
        margin-bottom: 0px;
        font-family: 'Sabon';
        font-weight: 400;
    }

    .overlay-text {
        font-size: 16px;
        color: var(--marketplaceColor);
        margin-bottom: 20px;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
    }

    .overlay-button {
        padding: 12px 20px;
        font-size: 14px;
        border: 0.5px solid;
        border-color: white;
    }
}

@media (max-width: 768px) {
    .overlay-title {
        font-size: 24px;
        color: var(--marketplaceColor);
        margin-bottom: 0px;
        font-family: 'Sabon';
        font-weight: 400;
    }

    .overlay-text {
        font-size: 14px;
        color: var(--marketplaceColor);
        margin-bottom: 20px;
        text-align: center;
        padding-left: 25px;
        padding-right: 25px;
    }

    .overlay-button {
        padding: 12px 20px;
        font-size: 12px;
        border: 0.5px solid;
        border-color: white;
    }
}